import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Chart from './Chart';
import Clock from './Clock';
import Recent from './Recent';

import React, { Component } from 'react'
import { Box } from '@material-ui/core';
import infobox from './infobox';
import InfoBox from './infobox';
import CookiePanel from '../helpers/cookiePanel';

const useStyles = theme => ({
	
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 500,
	},
});


class Home extends Component {


    render() {

        const { classes } = this.props;
		const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

        return (
            <>
            
            <Grid container spacing={1}>
                {/* Chart */}
                <Grid item xs={12} md={12} lg={12} >
                    <Paper style={{borderRadius: "15px 50px"}} className={fixedHeightPaper}>
                        <InfoBox></InfoBox>
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                
              
            </Grid>
           
            </>
        )
    }
}


export default withStyles(useStyles)(Home);



import React, { Component } from 'react';
import Particles from "react-tsparticles";
import { withStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import { loadFull } from 'tsparticles';


const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
}

const particlesLoaded = async (container) => {
    console.log(container);
}


const useStyles = theme => ({
	bubbles: {

        position: 'absolute',
        height: '100%',
        width: '100%',
        overflow: 'hidden',

        '& #bubbles' : {
            position: 'absolute',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
		},

        '& canvas' : {
            position: "relative !important",
            height: '100% !important',
            
		}
    }
});

class Bubbles extends Component {


    constructor(props) {
        super(props);
    }


    render() {
        const { classes } = this.props;
        return (
            <Box 
                className={ classes.bubbles }
            >
                
                <Particles
                    id="bubbles"
                    init={ particlesInit }
                    loaded={ particlesLoaded }
                    options={
                        {
                            "fullScreen": {
                                "enable": false,
                                "height": '100%',
                                "zIndex": 0
                            },
                            "particles": {
                                "number": {
                                    "value": 43,
                                    "density": {
                                        "enable": false,
                                        "value_area": 800
                                    }
                                },
                                "color": {
                                    "value": "#51D1E2"
                                },
                                "shape": {
                                    "type": "circle",
                                    "options": {
                                        "sides": 10
                                    }
                                },
                                "opacity": {
                                    "value": 0.2,
                                    "random": true,
                                    "anim": {
                                        "enable": true,
                                        "speed": 1,
                                        "opacity_min": 0.1,
                                        "sync": false
                                    }
                                },
                                "size": {
                                    "value": 24,
                                    "random": true,
                                    "anim": {
                                        "enable": false,
                                        "speed": 40,
                                        "size_min": 0.1,
                                        "sync": false
                                    }
                                },
                                "line_linked": {
                                    "enable": false,
                                    "distance": 600,
                                    "color": "#3F51B5",
                                    "opacity": 0.4,
                                    "width": 2
                                },
                                "move": {
                                    "enable": true,
                                    "speed": 2,
                                    "direction": "none",
                                    "random": true,
                                    "straight": false,
                                    "out_mode": "out",
                                }
                            },
                            "interactivity": {
                                "events": {
                                    "onclick": {
                                        "enable": true,
                                        "mode": "repulse"
                                    }
                                },
                            },
                            "background": {
                                "color": "transparent",
                                "image": "",
                                "position": "50% 50%",
                                "repeat": "no-repeat",
                                "size": "cover"
                            }
                        }
                    }
                />

            </Box>
        )
        }

}

export default withStyles(useStyles)(Bubbles);
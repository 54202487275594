import React, { useEffect, useState } from "react";
import "../../css/overlay.css";
import lang from "../../language/index";
import rotateImage from './rotate.png';
import { useHistory } from "react-router-dom";
function Overlay({ children, show }) {
	const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait) and (max-width: 768px)").matches);
    const [isOverlayVisible, setIsOverlayVisible] = useState(true);
	const history = useHistory();
	useEffect(() => {
	  const mediaQueryList = window.matchMedia("(orientation: portrait) and (max-width: 768px)");
	  const orientationChangeHandler = () => setIsPortrait(mediaQueryList.matches);
	
	  mediaQueryList.addEventListener("change", orientationChangeHandler);
  
	  return () => {
		mediaQueryList.removeEventListener("change", orientationChangeHandler);

	  };
	}, []);
	useEffect(() => {
		return history.listen(() => {
		  setIsOverlayVisible(true); // Reset isOverlayVisible when the path changes
		});
	  }, [history]);
	
	const handleCloseClick = () => {
		setIsOverlayVisible(false);
	  };
	return (
	  <div>
		{isPortrait && isOverlayVisible &&(
		  <div className="rotate-overlay" style={{"flexDirection":"column"}} onClick={handleCloseClick}>
				
					<div style={{"margin-bottom":"10px"}}>{lang.rotatescreen}</div>
				
				
					<img src={rotateImage}/>
				
		  </div>
		)}
		{children}
	  </div>
	);
  }
  export default Overlay;
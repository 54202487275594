import React, {  Fragment } from 'react'

import { IconButton } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import AnalysisDet from '../details/Analysis';

import lang from '../../language';
import Api from '../../helpers/api'

import { Link, useParams } from 'react-router-dom';
import Lists from '../core/Lists';
import './analysis.css'


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Analysis extends Lists {
    
    constructor(props){
        super(props);
        const {id} = this.props.params;
        this.analysis = 0;
        this.patientId = id;
        let customFieldDetails = {}
        this.props.checkToken()
        if(!(parseInt(this.state.auth.currentUser.role_id) == 3)){customFieldDetails = {
            
                "field": ' ',
                "resizable": false,
                "sortable": false,
                "disableColumnMenu": true,
                "align" : 'right',
                "width": 150,
                           
                "renderCell": (params) => (
                    <div>
                  
                        <IconButton 
                            component={Link} 
                            to={`/svalue/${params.id}`}
                            variant="contained" 
                            
                            title={lang.valid} 
                            onClick={ () => console.log("clicked") }> 
                                <QueryStatsIcon style={{ color: 'black  ' }} /> 
                        </IconButton>
                   
                        <IconButton 
                            
                            title={lang.edit} 
                            onClick={ () => this.onEditDetail(params) }> 
                                <EditIcon style={{ color: 'green' }} /> 
                        </IconButton>

                        <IconButton 
                            
                            title={lang.delete} 
                            onClick={ () => this.onDeleteConfirm(true, params) }> 
                                <DeleteIcon style={{ color: 'red' }} /> 
                        </IconButton>
                       
                    </div>
                )
            
        }}
        
        this.columns = [
            { field: 'id', headerName: 'ID', flex: 0.10,minWidth:150,hide:true },
            { field: 'id_user', headerName: lang.id_user, flex: 0.20,minWidth:150,hide:true },
            { field: 'datetime', headerName: lang.datetime, flex: 0.20,minWidth:150 },
            { field: 'duration', headerName: lang.duration, flex: 0.20,minWidth:150,valueGetter:(params)=>{

                
                if(!params.value){
                    return 0
                }
                // Split the string into two date strings
                const [startDateString, endDateString] = params.value.split(" - ");
                if(!startDateString){
                    return 0
                }
                
                let [datePart, timePart] = startDateString.split(" ");
                if(!timePart){
                    return 0
                }
                let [day, month, year] = datePart.split("/");
                let [hours, minutes, seconds] = timePart.split(":");

                // Note: Months are 0-based in the Date constructor, so we need to subtract 1 from the month
                const startDate = new Date(year, month - 1, day, hours, minutes, seconds);
                // Convert the date strings to Date objects
                if(!endDateString){
                    return 0
                }
                
                [datePart, timePart] = endDateString.split(" ");
                if(!timePart){
                    return 0
                }
                [day, month, year] = datePart.split("/");
                [hours, minutes, seconds] = timePart.split(":");
                // Note: Months are 0-based in the Date constructor, so we need to subtract 1 from the month
                const endDate = new Date(year, month - 1, day, hours, minutes, seconds);
                // Calculate the difference in milliseconds
                const durationInMillis = endDate - startDate;
                // Convert milliseconds to hours and minutes
                const hoursf = Math.floor(durationInMillis / (1000 * 60 * 60));
                const minutesf = Math.floor((durationInMillis % (1000 * 60 * 60)) / (1000 * 60));
                return(`${hoursf} h ${minutesf} m`);


            }},
            { field: 'value', headerName: lang.value, flex: 0.20,minWidth:150, cellClassName:(params)=> {
                const value = params.value;
                if (value <= 50) {
                  return 'low';
                } else if (value <= 120) {
                    return 'normal';
                } else if (value <= 360) {
                  return 'normal';
                } else if (value <= 600) {
                  return 'mild';
                } else if (value <= 900) {
                  return 'high';
                } else {
                  return 'very-high';
                }
            } },
            { field: 'threshold', headerName: lang.threshold, flex: 0.20,minWidth:150,valueGetter:(params)=> lang[params.row.threshold] },
            { field: 'note', headerName: lang.note, flex: 0.20,minWidth:150 },
            customFieldDetails,
        ];

    }
   

    onLoadData = async () => {
     
       if(this.state.auth){ 
            let doctorId= 0
            let patientId = 0
            if(this.state.auth.currentUser.role_id === 2 || this.state.auth.currentUser.role_id === 1){
                
                doctorId =  this.state.auth.currentUser.id
                patientId = this.patientId

            }else{
                patientId =  this.state.auth.currentUser.id
                
                doctorId = this.state.auth.currentUser.doctor_id
            }
            
         
            let url = null;
       
          
            if ( this.state.auth.currentUser.role_id === 2 || this.state.auth.currentUser.role_id === 1 ||this.state.auth.currentUser.role_id === 3){
                
                const data = await Api.get(Api.urls.users + "/"+doctorId+"/patient/" + patientId+"/analysis", null, {
                    'Authorization': 'Bearer ' + this.state.auth.token.token
                });
                
                if (data !== undefined ){
                  
                    const formattedDatetime = data.map( (element) =>{ 
                        if(element.datetime){
                            element.datetime = element.datetime.slice(0, 19).replace('T', ' ')
                            return element
                        }else{
                            return element
                        }
                    });
               
                    

                    this.rows = formattedDatetime;
                  
                }
               
            }
            else{
                window.location.href = '/';
                return;
            } 
        }
        this.setState({
            rows : this.rows.slice()
        });
    }


    
    onEditDetail = async props =>{
        const {id, id_patient} = props
        
        
        try {
            const analysis = await Api.get(Api.urls.analysis + '/' + id, {}, {
                'Authorization': 'Bearer ' + this.state.auth.token.token
            });
            
    
            if (analysis !== undefined && analysis.name === 'TokenExpiredError'){
                window.location.href = '/';
            }
            else if (analysis){
                this.analysis = {...analysis};
                this.setState({ detail_open: true})
            }    

        } catch (error) {
            console.log(error.message);
        }
    }


    onDeleteConfirm = ( open = true, props = null) =>{

        this.setState({ delete_confirm_open: open });
        
        if (open){
            this.id_to_delete = props.id;
        }
    }

    onDeleteAction = async () =>{

        //console.log("delete" +  this.id_to_delete);
        
        let id = this.id_to_delete;

        if (id != null ){

            if (this.state.auth.token ){
              
                const status = await Api.del(Api.urls.analysis + '/' + id ,{
                    'Authorization': 'Bearer ' + this.state.auth.token.token
                });

                if (status){
                    this.setState((state, props) => ({
                        rows: state.rows.filter( item => item.id !== id)
                    }));
                    this.message = lang["success"];
			
                    this.onErrorMessage(this.message,"success");
                }
                
                else{
                       //console.log(user.error);
                    this.message = lang.delete_error;
                    console.log("primo errore",this.message)
                    this.onErrorMessage(this.message);
                }
            }
        }
    }

    


    onMultiDeleteAction = async () =>{
        
        console.log("selected rowData:", this.selectedRowData);
        
        let refresh = false;

        for (const item of  this.selectedRowData) {
            const status = await Api.del(Api.urls.doctors + '/' + item.id ,null, {
                'Authorization': 'Bearer ' + this.state.auth.token.token
            });
           

            if (status)
                refresh = status;
        }

        if (refresh){
            this.onLoadData();
        }
    }



    onOKDetailAction = async (data) => {
       
        let status = undefined;
        const id_analysis = this.analysis.analysis[0].id
        if (data.note !== undefined){
            status = await Api.patch(Api.urls.analysis +  '/' + id_analysis, data, {
                'Authorization': 'Bearer ' + this.state.auth.token.token
            })
        }
        

        if (status === 204 || status === 200 || status === 201 ){

            this.setState({ detail_open: false })
            this.onLoadData();
            this.message = lang["success"];
			
            this.onErrorMessage(this.message,"success");
        }
        else{
            this.message = lang.errors[status];
            this.onErrorMessage({error_open:true});
        }     
    }


    getDetailDialog = () => (
        
        <AnalysisDet
            auth = {this.state.auth}
            open = {this.state.detail_open}
            detailClose = {this.onDetailClose} 
            onOK = {this.onOKDetailAction}  
            user = {this.user}
            analysis = {this.analysis}
        />
    )

    render() {

        return (        
            <Fragment >
                {this.getDataGridPage(lang.analysis, 'analysis', this.patientId)}
                {this.getConfirmDialog()}
                {this.getDetailDialog()}
                {this.getSnackbar()}
            </Fragment>
        )
    }
}

export default withParams(Analysis);

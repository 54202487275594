import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Bubbles from '../Bubbles';
import MenuIcon from '@material-ui/icons/Menu';
import { withTheme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';


import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { Route, Switch } from "react-router";

import ConfirmDialog from '../helpers/ConfitmDialog';
import Copyright from '../helpers/Copyright';
import Menu from './Menu';

import Home from './Home';

import lang from '../../language';
import Doctors from '../lists/Doctors';
import Patients from '../lists/Patients';
import Analysis from '../lists/Analysis';
import Savlue from '../lists/Svalue';
import User from '../details/User';
import api from '../../helpers/api';
import ChartPage from '../helpers/ChartPage'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { set } from 'date-fns';
import Overlay from '../helpers/overlay';

import CookiePanel from '../helpers/cookiePanel';


const theme = createMuiTheme({
	breakpoints: {
	  values: {
		mobile: 0,
		mobileSmall: 320,
		tablet: 640,
		laptop: 1024,
		desktop: 1200,
	  },
	},
  });


const drawerWidth = 240;

const useStyles = theme => ({
	root: {
		
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24,
		backgroundColor: "#05bad7" // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,

		},

		)
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		

		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),

	},

	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},

	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},

	container: {
		padding: theme.spacing(5),
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			padding: 0,
		},
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
});


class Dashboard extends Component {

	constructor(props) {
		super(props)
		console.log("::DashBoard")
		

		const {currentUser, token} = this.props;
		 

		this.state = {
		
			open: true,
			confirm_open: false,
			error_open : false,
			severity : "error",
			detail_current_user_open : false,
			auth : {
				currentUser,
				token
			},
			patientId : 0,
			drawerWidth: 190,
			isSmallScreen: window.innerWidth <= 600,
			
		};
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.handleResize);
		this.handleResize(); // Call handler right away so state gets updated with initial window size
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	  }
	handleResize = () => {
		
		this.setState({ 
		  drawerWidth: window.innerWidth <= 600 ? 50 : 190,
		  isSmallScreen: window.innerWidth <= 600
		});
		if (this.state.isSmallScreen && this.state.open) {
			this.handleDrawerClose();
		  }
	  };
	
	handleDrawerOpen = (e) => {
		
		
		this.setState({ open: true });
		
		
	}

	handleDrawerClose = (e) => {
		this.setState({ open: false });
	}

	onConfirmOpen = (e = true) => {
		this.setState({ confirm_open: e });
	}


	onExitToApp = () => {
		console.log("onExitToApp")
		this.props.onExitToApp();
	}


	onErrorMessage = (error_open = true, severity= "error") =>{ 
		console.log("sever",severity)
		this.setState({severity})
		//esegui dopo
		this.setState({ error_open })
	};


	onOKDetailCurrenteUserAction = async (data) => {
		//console.log(data);
		
		const status = await api.patch(api.urls.users +  '/' + data.id, data, {
			'Authorization': 'Bearer ' + this.state.auth.token.token
		})

		if (status === 204 || status === 200 ){


			this.setState(state => ({
				detail_current_user_open: false,
				auth : {
					...state.auth,
					currentUser : {
						...state.auth.currentUser,
						name : data.name,
						email : data.email
					}
				},
				patientId : 0

			}))
			this.message = lang["success"];
			
			this.onErrorMessage(this.message,"success");
			
        }
        else{
            this.message = lang.errors[status];
            this.onErrorMessage(true);
        }     
	}

	getDetailCurrenteUserDialog = () => (
		
        <User
            open = {this.state.detail_current_user_open}
            detailClose = {() => this.setState({detail_current_user_open:false})} 
            onOK = {this.onOKDetailCurrenteUserAction}  
            user = {this.state.auth.currentUser}
        />
    )

	
	
	getSnackbar = () => (
        
        <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open = {this.state.error_open}
            onClose={this.onErrorMessage}
		>

            <MuiAlert elevation={6} variant="filled" severity={this.severity} >{this.message}! </MuiAlert>

        </Snackbar>
    )


	checkAuth = () =>{
		//prendi il token dalla sessione
		const ciphertext = localStorage.getItem("token");

		if ( ciphertext === null ) {
			
			const defaultState = {
				user: null,
				token: null,
				error: false,
				forgot_message : null,
				
			};

			this.setState({
				...defaultState

			});
			//redirect to login
			window.location.href = '/';

			return ;
		}

	}


	render() {
		console.log("dashboard")
		const { classes } = this.props;
		
		return (
			<>
			
				<div className={classes.root}>
					{this.getDetailCurrenteUserDialog()}
					{this.getSnackbar()}
					<CssBaseline />
					
					<AppBar position="absolute"  className={clsx(classes.appBar, (this.state.open) && classes.appBarShift)}>
						
						<Toolbar className={classes.toolbar}>
						{!this.state.isSmallScreen && (
							<IconButton
								edge="start"
								color="inherit"
								aria-label="open drawer"
								onClick={this.handleDrawerOpen}
								className={clsx(classes.menuButton, this.state.open && classes.menuButtonHidden)}
							>
								<MenuIcon />
							</IconButton>
							    )}
							<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
								{this.props.title}
							</Typography>
							
							{/* <IconButton color="inherit">
								<Badge badgeContent={4} color="secondary">
									<NotificationsIcon />
								</Badge>
							</IconButton> */}

							<IconButton color="inherit" onClick={() => this.setState({detail_current_user_open:true})} title={this.state.auth.currentUser.name} >
								<AccountCircleIcon />
							</IconButton>

							<IconButton color="inherit" onClick={() => { this.onConfirmOpen(true) }} title={lang.exit_to_app} >
								<ExitToAppIcon />
							</IconButton>

							<ConfirmDialog

								title={lang.exit_to_app}
								open={this.state.confirm_open}
								setOpen={this.onConfirmOpen}
								onConfirm={this.onExitToApp}
							>
								{lang.exit_to_app_question}
							</ConfirmDialog>

						</Toolbar>
					</AppBar>
					<div style={ this.state.isSmallScreen ? {"display":"block",overflow: 'auto'} : {"display":"flex",height:"100%",overflow: 'auto',}}>
						{this.state.isSmallScreen  && 
						<div style={{"display":"flex","flexDirection":"row",overflow: 'auto', flexGrow: 1}}>
							<Menu  style={{"display":"flex","flexDirection":"row",overflow: 'auto', flexGrow: 1}} user={this.state.auth.currentUser} />
						</div>						}		
						{!this.state.isSmallScreen && (
							<Drawer
								variant="permanent"
								
								classes={{
									paper: clsx(classes.drawerPaper ,(!this.state.open ||  this.state.isSmallScreen) && classes.drawerPaperClose ),
								}}
								open={this.state.open}
							
							>
									
								<Bubbles/>
								<div className={classes.toolbarIcon}>
								
									<IconButton onClick={this.handleDrawerClose}>
										<ChevronLeftIcon />
									</IconButton>
								</div>
								
								<Divider />
								<List style={{height: '100%'}}><Menu user={this.state.auth.currentUser} /></List>
								<Divider />

								{/* <List>{secondaryListItems}</List> */}
								
							</Drawer>
						)}
						<main className={classes.content}>
						{!this.state.isSmallScreen && (<div className={classes.appBarSpacer} />)}

						<Container maxWidth={false} className={classes.container}>
						
								<Switch>
									
									<Route exact path="/doctors">
									<Overlay  ><Doctors auth = {this.state.auth} checkToken = {this.checkAuth}/></Overlay>
									</Route>
									<Route exact path="/patients">
									<Overlay><Patients  auth = {this.state.auth} checkToken = {this.checkAuth} /></Overlay>
									</Route>
									
									<Route exact path="/analysis/:id">
									<Overlay><Analysis  auth = {this.state.auth}  checkToken = {this.checkAuth}/></Overlay>
									</Route>
										
									
									<Route exact path="/chart/:idPatient">
									<ThemeProvider theme={theme}>
									<Overlay><ChartPage  auth = {this.state.auth} checkToken = {this.checkAuth}/></Overlay>
									
									</ThemeProvider>

									</Route>
									<Route exact path="/svalue/:idAnalysis">
									<Overlay><Savlue  auth = {this.state.auth} checkToken = {this.checkAuth}/></Overlay>
										
									</Route>
									<Route>
										<Home auth = {this.state.auth} checkToken = {this.checkAuth} />
									</Route>
								</Switch>
								
								{/* <Box pt={1}>
									<Copyright title={this.props.title} />
								</Box> */}

							<Box  style={{marginTop:"10%"}} >

								<section style={{padding:'10px 5px 10px 5px', backgroundColor:'#05bad7',display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center",marginTop:"20px"}}>
										
								<img style={{width:'50%'}} alt="register" src ='/gruppo_loghi_bianco.png' /> 

								<h3 style={{marginTop:'10px', color:"#fff", fontSize:"80%",  textAlign:"justify"}}>Il sostegno della Commissione europea alla produzione di questa pubblicazione non costituisce un'approvazione del contenuto, che riflette esclusivamente il punto di vista degli autori, e la Commisisone non può essere ritenuta responsabile per l'uso che può essere ritenuta responsabile per l’uso che può essere fatto delle informazioni ivi contenute. </h3>
								{/* <h3 style={{marginTop:'10px', color:"#fff", fontSize:"6px", textAlign:"center"}}>N. Progetto 08CT6202000208 – CUP G69J18001010007</h3> */}
								<Copyright style={{marginTop:'10px', color:"white"}} title={this.props.title} />
								</section>
							</Box>
						</Container>
					</main>
				</div>
			</div>
			
		</>
		);
	}
}


export default withStyles(useStyles)(Dashboard);

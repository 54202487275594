import React, { Component } from 'react';
import { ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import cookiePanel from '../helpers/cookiePanel';

import { Link } from "react-router-dom";
import Bubbles from '../Bubbles';

import lang from '../../language';


const useStyles = theme => ({
    box:{
        
        //background:" linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 8%, rgba(5,186,215,0.40379901960784315) 100%, rgba(0,0,0,0) 10%)",
        height: '100%',

        '& canvas' : {
            width: "100% !important",
            height: "auto !important",
		},
        position: "relative"
    },
	ListItem:{
       
		paddingLeft: '25px! important',
		paddingRight: '0! important',
		margin: 'auto !important',
		marginTop: '5px !important',
		marginBottom: '5px !important',
		
        background: "linear-gradient(#05bad7 0 0) var(--p,0)/var(--p,0) no-repeat",
        transition: ".4s,background-position 0s",
		'&:hover' : {
            "--p": "100%",
            color: "#fff",
		},
		'&:hover .ListItemIcon' : {
			color: '#fff'
		},
		'& .ListItemText > span' : {
			fontSize: '16px !important',
		},
		'&:active *':{
			color: '#fff'
		}

	},
    row:{
      
        "display":"flex",
        "justifyContent":"center",
        "flexDirection":"row",
        
    }
});

class Menu extends Component {

    constructor(props) {
        super(props);
        console.log("props", props)
        this.menu = {
            1: [{
                    icon : <DashboardIcon />,
                    name : "Dashboard",
                    href : '/dashboard'
                },{
                    icon : <LocalHospitalIcon />,
                    name : lang.doctors,
                    href : '/doctors'
                },{
                    icon : <GroupIcon />,
                    name : lang.patients,
                    href : '/patients'
                }
            ],

            2: [{
                    icon : <DashboardIcon />,
                    name : "Dashboard",
                    href : '/dashboard'
                },{
                    icon : <GroupIcon />,
                    name : lang.patients,
                    href : '/patients'
                }
            ],

            3: [{
                    icon : <DashboardIcon />,
                    name : "Dashboard",
                    href : '/dashboard'
                },{
                    icon : <GroupIcon />,
                    name : lang.analysis,
                    href : '/analysis/'+props.user.id
                }
            ]
        }
        this.state = {
            marginTop: 0,
            isSmallScreen: window.innerWidth <= 600
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
		this.handleResize(); // Call handler right away so state gets updated with initial window size
        const element = document.getElementsByClassName('MuiPaper-root MuiAppBar-root')[0];
        if (element) {
          this.setState({ marginTop: element.offsetHeight });
        }
      }
      componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	  }
      handleResize = () => {
		
		this.setState({ 
		  drawerWidth: window.innerWidth <= 600 ? 50 : 190,
		  isSmallScreen: window.innerWidth <= 600
		});
		if (this.state.isSmallScreen && this.state.open) {
			this.handleDrawerClose();
		  }
	  };
    render() {
		const { classes } = this.props;

        const list = this.menu[this.props.user.role_id || 1];

        return (
            <>
           
          
<Box className={ this.state.isSmallScreen ? classes.row : classes.box} style={{ 
    overflow: 'auto', 
    marginTop: this.state.isSmallScreen ? this.state.marginTop : '1px',
    maxHeight: '100vh', // 100% of the viewport height
    maxWidth: '100%', // 100% of the viewport width
    width:"100%",
    display: this.state.isSmallScreen ? "flex" : 'flex',
     flexDirection : this.state.isSmallScreen ? "row" : "column",
     justifyContent: this.state.isSmallScreen ? "space-evenly":"flex-start",
    // alignItems: this.state.isSmallScreen ? "center" : "flex-start",
     alignContent: this.state.isSmallScreen ? "center" : "center",
  } }>    
          
                { list.map( (item, index) => {

                        return (
                            <>
                                
                                <ListItem key={index} className={classes.ListItem} button component={ Link } to={item.href} variant="contained" title={item.name}
                              
                                style={{ 
                                     display:"flex",
                                    // flexDirection: this.state.isSmallScreen ? "row" : "row",                               
                                     
                                     alignSelf:"center",
                                    // width:"100%",
                                    // margin:"auto",
                                     justifyContent: this.state.isSmallScreen ? "flex-start":"space-evenly",
                                }}
                                >
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                    </ListItem>
                            </>
                        );
                    
                    })}
                  
                    {/* <Spline style={{width: '100%', height: 'auto', position:'absolute', top:'100%', transform: 'translateY(-100%)', paddingBottom: 25}} scene="https://prod.spline.design/ytDfSJtuGoxIKFmg/scene.splinecode" /> */}
                </Box>   
                
               
             
            </>
        )
    }
}

export default withStyles(useStyles)(Menu);
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import lang from '../../language';

export function Chart(props) {
const data = props.data
const chartType = props.chartType
let labels = []




if(chartType=="week"){
  labels = data.map((element) => {
    let timeData = new Date(element.datetime)
    timeData = timeData.toLocaleString('it-RM',{ weekday: "short",day: "numeric",month:"2-digit", year:"2-digit"})
    return timeData
         
  })
}
if(chartType=="day"){
  labels = data.map((element) => {
    let timeData = new Date(element.datetime)
    timeData = timeData.toLocaleString('it-RM',{ weekday: "short",day: "numeric",month:"2-digit", year:"2-digit"})
    return timeData
         
  })
}

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

 const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
 
  },
};



const datasChart = {
  labels,
  datasets: [
    {
      label: "Valore: ",
      data: data.map((element) => parseFloat(element.value)),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};


  return <div style={{ width: '100%', height: '100%' }}><Line options={options}  data={datasChart} /></div>;
}

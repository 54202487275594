    import React, {  Fragment } from 'react'

    import { IconButton } from '@material-ui/core';

    import DeleteIcon from '@material-ui/icons/Delete';
    import EditIcon from '@material-ui/icons/Edit';
    import QueryStatsIcon from '@mui/icons-material/QueryStats';

    import AnalysisDet from '../details/Analysis';

    import lang from '../../language';
    import Api from '../../helpers/api'
    import Assessment from '@material-ui/icons/Assessment';
    import { Link, useParams } from 'react-router-dom';
    import Lists from '../core/Lists';
    import './analysis.css'
    import { ControlCameraSharp } from '@material-ui/icons';

    function withParams(Component) {
        return props => <Component {...props} params={useParams()} />;
    }



    class Svalue extends Lists {
        
        constructor(props){
            super(props);
        
            this.idAnalysis = this.props.params.idAnalysis;
          
            this.props.checkToken()
            let customFieldDetails = {}
            if(!(parseInt(this.state.auth.currentUser.role_id) == 3)){customFieldDetails = {
                
                    "field": ' ',
                    "resizable": false,
                    "sortable": false,
                    "disableColumnMenu": true,
                    "align" : 'right',
                    "width": 150,
                    
            }}
            
            this.columns = [
            
                

            ];

        }
    

        onLoadData = async () => {

            if(this.state.auth){ 
                let doctorId= 0
                let patientId = 0
                if(this.state.auth.currentUser.role_id === 2 || this.state.auth.currentUser.role_id === 1){
                    
                    doctorId =  this.state.auth.currentUser.id
                    patientId = this.patientId

                }else{
                    patientId =  this.state.auth.currentUser.id
                    
                    doctorId = this.state.auth.currentUser.doctor_id
                }
                
            
                let url = null;
            
            
                if ( this.state.auth.currentUser.role_id === 2 || this.state.auth.currentUser.role_id === 1 ||this.state.auth.currentUser.role_id === 3){
                   
              
                    const data = await Api.get(Api.urls.analysis + "/"+ this.idAnalysis +"/svalue", null,{                    
                        'Authorization': 'Bearer ' + this.state.auth.token.token                
                    });
                
                    if (data !== undefined ){
                                 
                    }
                
                    
                let temp = JSON.parse(data.svalues[0].svalue)
                let count= 0
                let values=[]
                
                temp.forEach(element => {
                        element.id=count
                        count++
                        let valDouble= parseFloat(element.value)
                        let myRoundedFloat = valDouble.toFixed(2);
                        element.value = parseFloat(myRoundedFloat)
                    
            
                        if(values[0]) {
                            element.diff = parseFloat(parseFloat(myRoundedFloat) - values[0].value  ).toFixed(2)
                        } else{
                            element.diff = 0
                        }
                        values.push(element)
                    });
                    
                    this.rows=values
                }
                else{
                    //window.location.href = '/';
                    return;
                } 

            }
        //     diffsvalue:"S50-S0",
		// svalue0:"S0",
        if(this.rows.length > 10 ){
            this.columns = [
               
                { field: 'value', headerName: lang.svalue0, flex: 0.30 },
                { field: 'diff', headerName: lang.diffsvalue, flex: 0.30 } ,
                { field: 'time', headerName: lang.datetime, flex: 0.30 },
              
            ]
        }else{
            this.columns = [

                { field: 'value', headerName: lang.diffsvalue, flex: 0.30 },
                { field: 'time', headerName: lang.datetime, flex: 0.30 }

            ]
        }
           
            this.setState({
                rows : this.rows.slice()
            });

        }





        render() {

            return (        
                <Fragment>
                    {this.getDataGridPage( lang.svalue, 'svalue', this.id )}
                    {this.getConfirmDialog()}
                    {this.getDetailDialog()}
                    {this.getSnackbar()}
                </Fragment>
            )
        }
    }

    export default withParams(Svalue);

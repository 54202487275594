import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { useEffect,  useState } from "react";

import { useParams } from "react-router-dom";
import config from "../../config";
import lang from "../../language";

import { v4 as uuidv4 } from 'uuid';
import serialize from "form-serialize";
import { useCookies } from "react-cookie";

const switch_c = [true, false, false];

  
function CookieSetPanel(props) {


    let { lang: langCode = undefined } = useParams();

    if (!langCode) {
        langCode = lang.getLanguage().toString();
    }


    const [cookies, setCookie] = useCookies([]); 
    const [open, setOpen ] = useState(false) ;


    function handleClose( e, r) {

        if (r && r === 'backdropClick'){
            return;
        }

        setOpen(false);
    }



    function onAcceptButton() {

        const date = (new Date()).getDate();

        const categories = [
            "necessary",
            "analytics",
            "targeting"
        ];


        const ccCookie = {
            categories,
            "revision":0,
            "data":null,
            "rfc_cookie":false,
            "consent_date": date,
            "consent_uuid": uuidv4(),
            "last_consent_update": date
        };

        

        const expires = new Date();
        expires.setDate(new Date().getDate() + 185);

        setCookie('ccCookie', ccCookie, { 
            path: '/',
            expires
        });

        setOpen(false);

        // if (props.onClose){
        //     props.onClose();
        // }

        if (props.onClose){
            props.onClose(categories);
        }

    }


    function onSaveButton(e) {

        e.preventDefault();

        const data = serialize(e.target, { hash: true });

        const categories = ['necessary'];


        if (data.Switch1){
            categories.push('analytics');
        }

        if (data.Switch2){
            categories.push('targeting');
        }

    
        const date = (new Date()).getDate();

        const ccCookie = {
            categories,            
            "revision":0,
            "data":null,
            "rfc_cookie":false,
            "consent_date": date,
            "consent_uuid": uuidv4(),
            "last_consent_update": date
        };

        const expires = new Date();
        expires.setDate(new Date().getDate() + 185);

        setCookie('ccCookie', ccCookie, { 
            path: '/',
            expires
        });

        
        setOpen(false);
        
        if (props.onClose){
            props.onClose(categories);
        }
    }

    
    useEffect(() => {

        setOpen(true);
        
        //const c = {...cookies};
        //console.log(c.);

        if (cookies.ccCookie){
            //console.log(cookies.ccCookie.categories);
            
            const analytics = cookies.ccCookie.categories.find( item => item === 'analytics')
            const targeting = cookies.ccCookie.categories.find( item => item === 'targeting')

            switch_c[1] = analytics !== undefined;
            switch_c[2] = targeting !== undefined;

            console.log(switch_c);

        }

    }, []);


    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >

            <form onSubmit={onSaveButton}  >

                <DialogTitle color="secondary" >
                    {lang.cookieSetPanel.title}
                    <Typography  color="secondary" variant="body1">
                        {lang.cookieSetPanel.subTitle}
                    </Typography>
                </DialogTitle>
                <DialogContent>

                    <Typography variant="body2" color="secondary" >
                        <span dangerouslySetInnerHTML={{ __html: lang.cookieSetPanel.content.replace('%s', "https://pku-smart-sensors.eu/privacy-policy/") }}></span>
                    </Typography>

                    <Box sx={{my:3, p:1}}>

                        {lang.cookieSetPanel.category.map( (item, index) => (

                            <Accordion key={index} sx={{mb: 2}} expanded={expanded === 'panel' + index } onChange={handleChange('panel' + index)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1bh-content"
                                
                                >
                                    <FormGroup>
                                        <FormControlLabel color="secondary" disabled={index === 0} control={<Switch name={"Switch" + index} defaultChecked={switch_c[index]} color="secondary" />} 
                                            label={item.title} />
                                    </FormGroup>

                                </AccordionSummary>
                              
                            </Accordion>

                        ))}

                    </Box>

                    <Box >

                        <Typography color="secondary" variant="h6" sx={{mb:2}}>
                            {lang.cookieSetPanel.moreContent.title}
                        </Typography>

                        <Typography color="secondary" variant="body2">
                            <span dangerouslySetInnerHTML={{ __html: lang.cookieSetPanel.moreContent.content.replace('%s',  'https://pku-smart-sensors.eu/contatti/') }}></span>
                        </Typography>

                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onAcceptButton} autoFocus>{lang.cookieSetPanel.acceptButton}</Button>
                    <Button  variant="outlined" /*onClick={onSaveButton}*/ type="submit" >{lang.cookieSetPanel.saveButton}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )

}



export default CookieSetPanel;

import { Info } from "@material-ui/icons";
import React, { Component } from 'react';
import { Box } from '@material-ui/core';

class InfoBox extends Component {
    render() {
        return (
            <div style={{ background: "#f2f2f2", padding: "20px", borderRadius:"10%" }}>
                <div style={{ justifyContent: "center", display: "flex", marginBottom: "20px" }}>
                    <img src="./pku-logo.png" alt="logo" style={{ maxWidth: "100%" }}  />
                </div>
                
                <Box>
                  
                    <Box>
                        <h2 style={{ marginBottom: "40px",  fontSize: "20px", fontWeight: "bold", textAlign: "center", }}>La nuova generazione di biosensori: l'innovativo sistema di monitoraggio Point-of-Care di fenilalanina</h2>
                        <p style={{ marginBottom: "40px",fontSize: "18px", textAlign: "center",  }}>Il progetto PKU-smart-sensor mira alla realizzazione di un dimostratore portatile Point-of-Care (PoC), per il monitoraggio dei livelli di fenilalanina (Phe) nei pazienti affetti da iperfenilalaninemie (HPA) ed alla dimostrazione della sua funzionalità in ambiente operativo reale</p>
                        <p style={{ marginBottom: "20px", fontSize: "18px", textAlign: "center",  }}>Per ulteriori informazioni:</p>
                        <a style={{ marginBottom: "20px", fontSize: "18px", display: "block", textAlign: "center", textDecoration: "none", color: "#05bad7" }} href="https://www.pku-smart-sensors.eu/">PKU Smart Sensors</a>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default InfoBox;

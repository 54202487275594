import React, { Component } from 'react';
import Button from '@material-ui/core/Button';


import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';


import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import {withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {Link} from "react-router-dom";

import serialize from 'form-serialize';
import ReCAPTCHA from "react-google-recaptcha";

import Copyright  from './Copyright';

import config from '../../config.json'
import lang from '../../language'
import { Typography } from '@material-ui/core';
import Particle from '../Particle';
import axios from 'axios';
import api from '../../helpers/api';
import { LaptopWindows } from '@material-ui/icons';


  

const useStyles = theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(3, 0, 2, 0),
		
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	textfield: {
		'& fieldset': {
			backgroundColor: 'rgba(255, 255, 255, 0.6)'
		}
	},
	hidden: {
		display: 'none'
	}
});




class ForgotPassword extends Component {

	constructor(props){
		super(props);

		this.error_force_close = false;
		this.error_open = this.props.error !== null;

		this.recaptchaRef = React.createRef();
		this.recaptchaSitekey = config.recaptchaSitekey;
		this.emailUser = null
		this.state = {
			sentCode: false,
			successCode: false
		  };
	}
	 sendCodeToServer = async e  => {
		e.preventDefault();
		const data = serialize(e.target, { hash: true });
		this.emailUser = data.email;
		const token = await this.recaptchaRef.current.executeAsync();
		try {
	    //print token
		  console.log(api);
		  const response = await axios.post(api.urls.generate_code, {...data, 'g-recaptcha-response' : token});
		  console.log(response.status);
		  if(response.status === 200){
				this.setState({sentCode: true})
			}else{
				console.log("dentro l'errore",response.status)
				if(response.status==404 || response.status == 401){
					
					this.props.onError(lang.reset_password_error);
				}
			}
		  // Handle the response and perform any necessary actions
		
		} catch (error) {
			this.props.onError(lang.reset_password_error);
		  // Handle any errors that occurred during the request
		  console.error('Error generating code:', error);
		}
		
		return
	  }
	

	  //controlla se il codice ricevuto è corretto con quello salvato nel database, dopo di che chiama la funzione esistenete "resetPassword"
	  checkCode = async e => {
		e.preventDefault();
		const data = serialize(e.target, { hash: true });
		
		data.email = this.emailUser 
		const token = await this.recaptchaRef.current.executeAsync();
		try {
		  const response = await this.props.onForgotPassword({...data, 'g-recaptcha-response' : token});
		
		  if(response === 200){
				//porta l'utente alla home
			
				window.location.href = "/";
				
			}
		} catch (error) {
				console.error('Error checking code:', error);
				
		}
	}
		



	//

	onRecaptchaChange = e =>{
		//console.log(e);
	}
	handleClick = () => {
		console.log("in handle cli")
		this.setState({successCode: false})
		this.setState({sentCode: false})
	}


	render() {
		const {classes} = this.props;


		return (
			<>
			{console.log(this.state.successCode)}
				<Particle/>
				<Container component="main" maxWidth="xs">
					
					<Snackbar
						autoHideDuration={5000}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						open = {this.props.messagge !== null}
						onClose={this.props.onErrorMessage}
						>

						<MuiAlert 
							elevation={6} 
							variant="filled" 
							severity={this.props.messagge ? this.props.messagge.severity : 'success'} >
								{this.props.messagge ? this.props.messagge.text : ''} 
							</MuiAlert>
					</Snackbar>
					

					<CssBaseline />
					<div className={classes.paper}>
					
						<img alt={this.props.title} className={classes.avatar} src="/pku-logo.png" /> 
						{this.state.successCode ===false ? 
						<>
						<form onSubmit={this.sendCodeToServer} className={classes.form}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email"
								name="email"
								type="email"
								autoComplete="email"
								//className if !this.sentCode is false should be classes.hidden
								className={!this.state.sentCode ? classes.TextField : classes.hidden}
								autoFocus
							/>
						
							<ReCAPTCHA
									ref={this.recaptchaRef}
									sitekey={this.recaptchaSitekey}
									size="invisible"
									onChange={this.onRecaptchaChange}
								/>
						
							<Button
								type="submit"
								fullWidth
								variant="contained"
								style={{backgroundColor:"#3f51b5",color:"#fff"}}
								className={!this.state.sentCode ? classes.submit : classes.hidden}
							>
									{lang.request_password}
							</Button>

						
									
						
						</form>
						
						<form onSubmit={this.checkCode} className={classes.form}>
						
							<TextField
								variant="outlined"
								margin="normal"								
								fullWidth
								id="code"
								label="Code"	
								name="code"
								type="code"
								autoComplete="code"
								className={this.state.sentCode ? classes.TextField : classes.hidden}
								autoFocus
							/>
							<ReCAPTCHA
									ref={this.recaptchaRef}
									sitekey={this.recaptchaSitekey}
									size="invisible"
									onChange={this.onRecaptchaChange}
								/>
						
							<Button
								type="submit"
								fullWidth
								variant="contained"
								style={{backgroundColor:"#3f51b5",color:"#fff"}}
							
								className={this.state.sentCode ? classes.submit : classes.hidden}
							>
									{lang.request_password}
							</Button>
							<Typography align="center">
								<Link to="/" variant="body2">
									{lang.sign_in}
								</Link>
							</Typography>
							
									
						
						</form>
						</>
					
						:
						<>
							
							<p
								style={{color:"green",fontSize:"20px",fontWeight:"bold"}}
							>{lang.resetted_password}</p>
							 <Link to="/" className={classes.link}>
								<Button
									fullWidth
									variant="contained"
									style={{ backgroundColor: "#3f51b5", color: "#fff" }}
									className={classes.submit}
									onClick={this.handleClick}
										
								
								>		
									{lang.homepage}
								</Button>
								</Link>
						
						
						</>
						}

					</div>

					<Box mt={8}>
						<Copyright title={this.props.title} />
					</Box>

				</Container>
			</>
		);
	}
}

export default withStyles(useStyles)(ForgotPassword);
import {enUS, itIT } from '@material-ui/data-grid';
import LocalizedStrings from 'react-localization';



const language = 'it';

const strings = new LocalizedStrings({

	en: {
		cookiePanel : {
			title : 'We use cookies!',
			content: 'Hi, this site uses essential cookies to ensure it works properly and tracking cookies to understand how you interact with it. The latter will only be set with your consent. Choose',
			acceptButton : 'Accept all',
			customiseButton: 'Customise'
		},
		cookieSetPanel : {
			title : 'Cookie Preferences',
			subTitle : 'Use of Cookies',
			content: 'Cookies are used to ensure the basic functionality of the website and to improve your online experience. You can opt-in/out of each category at any time. For more details about cookies and other sensitive data, please read the full <a target="_blank" href="%s">privacy policy</a>.',

			category : [
				{
					title : 'Strictly necessary cookies',
					content : 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not function properly'
				},
				{
					title : 'Performance and analysis cookies',
					content : 'These cookies allow the website to remember choices you have made in the past'
				},
				

			],

			moreContent :{
				title : 'More information',
				content : 'If you have any questions regarding our cookie policy and your choices, please <a target="_blank" href="%s">contact us</a>.'
			},

			acceptButton : 'Accept all',
			saveButton: 'Save settings'
		},
		close : 'Close',
		closing : 'Closing',
		cookiesPolicy: 'Cookies policy',

		add_element : 'Add new element', 

		cancel : 'Cancel',
		clock : 'Clock',

		delete : 'Delete',
		delete_question : 'Are you sure you want delete this element?',
		doctor : 'Doctor',
		doctors : 'Doctors',


		email : 'Email',
		edit : 'Edit',
		errors :{
			409 : 'Username or email already used'
		},
		exit_to_app : "Exit to app",
		exit_to_app_question : "Are you sure you want to exit?",

		forgot_password : "Forgot password?",

		invalid_credentials: "Invalid credentials",

		name : 'Name',
		no : "No",

		ok : 'Ok',
		
		patient	: 'Patient',
		patients : 'Patients',
		
		recents : 'Recents',
		refresh: 'Refresh',
		request_password : 'Request password',
		reset_password_error : 'The user was not found',
		reset_password_success : 'The Password has been changed',
		

		search : "Search...",
		see_more : "See more",
		send : 'Send',
		sign_in: "Sign In",
		
		tests : 'Tests',

		user : 'User',
		username : 'Username',

		yes : "Yes",

		datetime : "Datetime",
		id_user: "ID user",
		value: "Value",

		analysis: "Analysis",
		threshold:"Treshold",
		"Low":"Low",
    "Normal":"Normal",
    "Acceptable":"Acceptable",
    "High":"High",
    "Extreme":"Very High",
    "Moderate":"Moderate",
    "High":"High",
		note:"Notes",
		svalue:"Check SX - S0",
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
		month:"Months",
		day:"Day",
		week:"Week",
		from:"From",
		to:"To",
		select_range:"Change range:",
		select_type:"Select type:",
		back:"Back",
		forward:"Forward",
		change_view:"Chart",
		change_view_table: "Table",
		valid:"SX-S0",
		chooseFile:"Choose a File",
		delete_error:"Can't Delete",
		delete_success:"Delete Success",
		duration:"Duration",
	
		downloadCSV:"Download CSV",
		importCSV:"Import CSV",
		downloadCSVexample:"Download example CSV",
		homepage:"Homepage",
		resetted_password:"Resetted password",
		wrong_code:"Wrong Code",
		success:"Success",
		error:"Error Upload CSV",
		diffsvalue:"SX-S0",
		svalue0:"SX",
		rotatescreen:"Please rotate your screen",
	},

	it: {
		add_element : 'Aggiuungi un nuovo elemento', 

		cancel : 'Annulla',
		clock : 'Orologio',

		delete : 'Cancella',
		delete_question : 'Vuoi cancellare l\'elemento?',
		doctor : 'Dottore',
		doctors : 'Dottori',

		email : 'Email',
		edit : 'Modifica',
		errors :{
			409 : 'Utente o email gia utilizzati'
		},
		exit_to_app : "Uscita dall'app",
		exit_to_app_question : "Sei sicuro di voler uscire all'app?",

		forgot_password : "Password dimenticata?",

		invalid_credentials: "Credenziali invalide",

		name : 'Nome',
		no : "No",
		
		cookiePanel : {
			title : 'Usiamo i cookie!',
			content: 'Salve, questo sito utilizza cookie essenziali per garantire il corretto funzionamento e cookie di monitoraggio per capire come interagite con esso. Questi ultimi vengono impostati solo con il vostro consenso. Scegliete',
			acceptButton : 'Accettare tutto',
			customiseButton: 'Personalizzare'
		},
		cookiesPolicy: 'Politica sui cookie',

		cookieSetPanel : {
			title : 'Preferenze sui cookie',
			subTitle : 'Utilizzo dei cookie',
			content: `I cookie vengono utilizzati per garantire la funzionalità di base del sito web e per migliorare la vostra esperienza online. È possibile scegliere di aderire o meno a ciascuna categoria in qualsiasi momento. Per ulteriori dettagli sui cookie e altri dati sensibili, si prega di leggere l'intera <a target="_blank" href="%s">informativa sulla privacy</a>.`,

			category : [
				{
					title : 'Cookie strettamente necessari',
					content : 'Questi cookie sono essenziali per il corretto funzionamento del mio sito web. Senza questi cookie, il sito non potrebbe funzionare correttamente.'
				},
				{
					title : 'Cookie di prestazione e analisi',
					content : `Questi cookie consentono al sito web di ricordare le scelte effettuate dall'utente in passato`
				},
				{
					title : 'Cookie pubblicitari e di profilazione',
					content : `Questi cookie raccolgono informazioni sull'utilizzo del sito web, sulle pagine visitate e sui link cliccati. Tutti i dati sono anonimizzati e non possono essere utilizzati per identificare l'utente.`
				}

			],

			moreContent :{
				title : 'Ulteriori informazioni',
				content : 'In caso di domande sulla nostra politica sui cookie e sulle vostre scelte, vi preghiamo di <a target="_blank" href="%s">contattaci</a>.'
			},

			acceptButton : 'Accettare tutto',
			saveButton: 'Salva le impostazioni',
		},
		close: 'Chiuso',
		closing : 'Data di fine',

		ok : 'Ok',

		patient	: 'Paziente',
		patients : 'Pazienti',

		recents : 'Recenti',
		refresh: 'Ricarica',
		request_password : 'Richiedi password',
		reset_password_error : 'L\'utente non è stato trovato.',
		reset_password_success : 'La password è stata cambiata.',
		

		search : "Cerca...",
		see_more : "Vai alla Pagina",
		send : 'Invia',
		sign_in: "Accedi",

		tests : 'Analisi',

		user : 'Utente',
		username : 'Username',
		
		yes : "Si",

		datetime : "Data",
		id_user: "ID utente",
		value: "Valore",
		analysis: "Analisi",
		threshold:"Soglia",
		Low:"Bassa",
		Normal:"Normale",
		Acceptable:"Accettabile",
		High:"Alta",
		Extreme:"Molto Alta",
		Moderate:"Moderata",
		note:"Note",
		svalue: "Controllo SX - S0",
		monday: "Lunedì",
		tuesday: "Martedì",
		wednesday: "Mercoledì",
		thursday: "Giovedì",
		friday: "Venerdì",
		saturday: "Sabato",
		sunday: "Domenica",
		month:"mese",
		day:"Giorno",
		week:"Settimana",
		from:"Da: ",
		to:"A ",
		select_range:"Cambia il range: ",
		select_type:"Seleziona il tipo di grafico:",
		forward:"Succesivo",
		back:"Precedente",
		change_view:"Grafico",
		change_view_table:"Tabella",
		valid:"SX-S0",
		chooseFile:"Scegli un file",
		delete_error:"Impossibile Eliminare",
		duration:"Durata",
		downloadCSV:"Scarica CSV",
		importCSV:"Importa CSV",
		downloadCSVexample:"Scarica CSV di esempio",
		homepage:"Homepage",
		resetted_password:"Password resettata",
		wrong_code:"Codice Errato",
		success:"Ok",
		delete_success:"Eliminato con Successo",
		diffsvalue:"SX-S0",
		svalue0:"SX",
		rotatescreen:"Perfavore ruota lo schermo",
	}
});


strings.locale = language === 'it' ? itIT : enUS; 
strings.setLanguage(language);

export default strings

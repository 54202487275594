    import React, { Component } from 'react'
    import { withStyles, fade } from '@material-ui/core/styles';
    import Box from '@material-ui/core/Box';


    import SearchIcon from '@material-ui/icons/Search';
    import AddCircleIcon from '@material-ui/icons/AddCircle';
    import DeleteIcon from '@material-ui/icons/Delete';
    import RefreshIcon from '@material-ui/icons/Refresh';

    import { DataGrid } from '@mui/x-data-grid';
    import "../../css/chartComponent.css"
    import ConfirmDialog from '../helpers/ConfitmDialog';

    import lang from '../../language';

    import GetAppIcon from '@material-ui/icons/GetApp';

    import CloudUploadIcon from '@material-ui/icons/CloudUpload';
    import EqualizerIcon from '@material-ui/icons/Equalizer';
    import axios from 'axios';
    import Api from '../../helpers/api'
    import { Link } from 'react-router-dom';
    import ChartIcon from '@material-ui/icons/InsertChartOutlined';

    import Input from '@mui/material/Input';
    import Alert from '@mui/material/Alert';
    import AlertTitle from '@mui/material/AlertTitle';
    import { BrowserRouter as Router, Route } from 'react-router-dom';
    import { Button } from '@mui/base/Button';


    import {
        Typography,
        InputBase,
        Toolbar,
    
        IconButton,
        
        Dialog,
        DialogTitle,
        DialogContent,
        DialogActions,
        CircularProgress,
    } from '@material-ui/core';
    
    const useStyles = theme => ({

        container: { 
            backgroundColor:"#fff", 
        size:10 ,
        boxShadow:" 2px 5px #faf2f2",
        padding:'25px',
        borderRadius:" 15px 50px 30px",
        play: 'flex',
        flexDirection: 'row',
        justifyContent: 'center', 
        alignItems: 'stretch',
     
       
        minHeight: '82vh', 
            [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
            width:'fit-content',
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: '0px',
                paddingRight: '0px',
                width:'100%',
                overflowX:'scroll'
                },
                [theme.breakpoints.down('lg')]: {
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    width:'100%',
                    overflowX:'scroll'
                    },
        },
           
        subContainer: { flexGrow: 1,
             width: '100%', 
         
            },

        root: {
            flexGrow: 1,
            minWidth: 0
        },

        menuButton: {
            marginRight: theme.spacing(1),
        },

        title: {
            flexGrow: 2,
            fontWeight: 'bold',
        },

        checkboxSelection:{
            color:'#05bad7',
        },
        
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '50%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        
        addCircleIcon:{
            '&:hover': {
                backgroundColor: fade('#05bad7',0.8),
                color: '#fff'
            },
            
        },
        refreshIcon:{
            '&:hover': {
                backgroundColor: fade('#05bad7',0.8),
                color: '#fff'
            },
        },


        
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },

        dataGrid: {
            "& .MuiCheckbox-colorPrimary.Mui-checked":{
                color: "rgb(5, 186, 215)",
            },   
                 
        },
        modalContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(4),
            minWidth: 300,
        },
        modalButton: {
            marginTop: theme.spacing(2),
        },

        


    });



    class DatagridPage extends Component {

        constructor(props){

            super(props);
            
            this.state = {
                delete_confirm_open : false,
                selection_size : 0, 
                showPassword : false,
                matches : window.matchMedia("(min-width: 560px)").matches,
                auth: props.auth,
                UploadOK:false,
                isModalOpen: false, // Add new state for modal
                isUploading: false, // Add new state for showing loading indicator during upload
                errorUpload: false
            }
            
            this.handleModal = this.handleModal.bind(this);
        }

        handleModal() {
            this.setState({ isModalOpen: true });
        }
        
        handleCloseModal() {
            this.setState({ isModalOpen: false });
        }

        componentDidMount = () => {
            const handler = e => this.setState({matches: e.matches});
            window.matchMedia("(min-width: 560px)").addEventListener('change', handler);
            
        }

        onSelectionModelChange = e => {
        
            //console.log();
            this.setState({selection_size : new Set(e.selectionModel).size});

            this.props.onSelectionModelChange(e)
        }


        onConfirmDialog = ( open ) =>{
            this.setState({ delete_confirm_open: open });
        }

        handleErrorUpload = (errorText) => {
            this.setState({ errorUpload: errorText });
        }
        handleDownloadCSV = () => {
            
            const { rows, columns } = this.props;
            const csvContent = this.generateCSVContent(rows, columns);
        
            const encodedUri = encodeURI(`data:text/csv;charset=utf-8, ${csvContent}`);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        generateCSVContent = (rows, columns) => {
            let headers = columns.map(column => column.field);
            headers.push("role_id");
            let newHead=[]
            headers = headers.map((item) => { 
                if (item !== " " && item !== "" && item !== null && item !== undefined) {
                newHead.push(item);
                }
            });
            headers = newHead
            console.log(headers)
        
            const rowsCSV = rows.map(row => headers.map(
                (header) => {
                    if(header=="Doctor"){
                        row[header] = row[header].name
                    }
                    return row[header]
                }).join(',')
            );
            const csvContent = [headers.join(','), ...rowsCSV].join('\n');
            return csvContent;
        };
        handleFileUpload = event => {
        
        
            const file = event.target.files[0];
            const reader = new FileReader();
        
            reader.onload = () => {
            const csvContent = reader.result;
            this.importCSVData((csvContent));
            };
        
            reader.readAsText(file);
        };
        

        importCSVData = async csvContent => {

            
            // Effettua la chiamata al backend per importare i dati
            await axios.post(Api.urls["import_"+this.props.baseUrl],{csvContent},{
                headers: {
                'Authorization': 'Bearer ' + this.state.auth.token.token
            
                }
                })
            .then(response => {
                // Esegui le azioni necessarie dopo l'importazione dei dati
                console.log('Dati importati con successo');
                // Esempio: Ricarica i dati nella tabella
                //setta UploadOK a true
                this.setState({ UploadOK: true });
                //dopo 2 secondi setta UploadOK a false
                setTimeout(() => {
                    this.setState({ UploadOK: false });
                }, 2000);
                this.handleErrorUpload(false)
                this.props.onReload();
            })
            .catch(error => {
                console.error('Errore durante l\'importazione dei dati', error);
                this.handleErrorUpload("Errore durante l\'importazione dei dati")
                // Gestisci l'errore in modo appropriato
            }).finally(() => {
            
                console.error('Finally');
                this.setState({ isUploading: false, isModalOpen: false });
                
                
            })
        };
        handleDownloadExampleCSV = async () => {
            try {
        
                const downloadLink = document.createElement('a');
                downloadLink.href = Api.urls["exampleCSV_"+this.props.baseUrl] // Specifica il percorso relativo al file all'interno della cartella "public"
                downloadLink.download = 'example.csv'; // Specifica il nome del file scaricato

                // Simula un clic sul link per avviare il download
                downloadLink.click();
        
            } catch (error) {
            console.error('Error downloading example CSV', error);
            }
        };

        render() {

            const { classes } = this.props;
            console.log("classes",this.props)
            return (

                <div className={classes.container} >

                    <div className={classes.subContainer} >

                        <div className={classes.root}>
                            {(this.props.baseUrl == "analysis") &&
                            // <Button
                            //                 variant="contained"                                       
                                            
                            //                 component={Link}
                            //                 to={"/chart/"+this.props.patientId}
                            //                 startIcon={<EqualizerIcon />}                                    
                                        
                            //                 >
                            //                 Chart
                                                
                            // </Button>
                            <></>
                        }    
                        {(this.state.errorUpload)&&
                                        <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {this.state.errorUpload}
                                        </Alert>
                                    }
                              {(this.state.UploadOK)&&
                                        <Alert severity="success">
                                        <AlertTitle>{lang.success}</AlertTitle>
                                        {this.state.errorUpload}
                                        </Alert>
                                    }      
                            { (this.props.auth.currentUser.role_id == 1 && this.props.baseUrl != "svalue") &&
                                <>

                                
                                    <Button  className="custom-button-grid" onClick={this.handleDownloadCSV} color="primary" variant="contained" startIcon={<GetAppIcon />}>
                                        {lang.downloadCSV}
                                    </Button>
                                    
                                    <Button
                                        className="custom-button-grid"
                                        variant="contained"
                                        component="label"
                                        style={{marginBottom:"5px"}}
                                        startIcon={<CloudUploadIcon />}
                                        onClick={this.handleModal}

                                    >
                                    {lang.importCSV}
                                    
                                    </Button>
                                    <Dialog
                                        open={this.state.isModalOpen}
                                        onClose={() => this.handleCloseModal()}
                                    >
                                    <DialogTitle>{lang.importCSV}</DialogTitle>
                                    <DialogContent style={{"max-width":"100%"}}>
                                    <div className={classes.modalContent} style={{"max-width":"100%"}}>
                                        {this.state.isUploading ? (
                                        <CircularProgress />
                                        ) : (<>
                                            <Button
                                            className="custom-button-grid"
                                            variant="contained"
                                            style={{marginBottom:"5px",maxWidth:"100%"}}
                                            color="primary"
                                            startIcon={<GetAppIcon />}
                                            onClick={this.handleDownloadExampleCSV}
                                        >
                                            {lang.downloadCSVexample}
                                        </Button>
                                            <Button
                                            className="custom-button-grid"
                                            style={{marginBottom:"5px",maxWidth:"100%"}}
                                            variant="contained"
                                            component="label"
                                            startIcon={<CloudUploadIcon />}
                                            onClick={this.handleModal}
                                            >
                                            Upload CSV
                                                <Input
                                                    type="file"
                                                    accept=".csv"
                                                    style={{ display: 'block',maxWidth:"100%" }}
                                                    onChange={this.handleFileUpload}
                                                />
                                            </Button>
                                            
                                            </>
                                        )}
                                        <Button
                                        className="custom-button"
                                        variant="contained"
                                        
                                        onClick={() =>   this.handleCloseModal()}
                                        >
                                        {lang.cancel}
                                        </Button>
                                    </div>
                                    </DialogContent>
                                </Dialog>
                                </>
                            }
                            { ( this.props.baseUrl == "analysis") &&
                                <Link style={{alignSelf:"center"}}to={"/chart/" + this.props.patientId}>
                                <Button className="custom-button-grid"  color="primary" variant="contained" startIcon={<ChartIcon />}>
                                {lang["change_view"]}
                                </Button>
                                </Link>
                            }
                            <Toolbar>
                                <Typography style={{color:'#05bad7'}} component="h2" variant="h6" className={classes.title}>
                                    {this.props.title}
                                </Typography>

                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={lang.search}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange ={this.props.onSearch}
                                        
                                    />
                                </div>

                                
                                { (this.props.baseUrl!="analysis") &&
                                <IconButton className={classes.addCircleIcon} onClick={this.props.onAdd} color="#05bad7" title={lang.add_element}>
                                    <AddCircleIcon  />
                                </IconButton>
                                 }
                                {this.state.selection_size !== 0 && (
                                    <IconButton onClick={ () => this.onConfirmDialog(true)} color="#05bad7" title={lang.delete}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                                

                                <ConfirmDialog
                                    title={lang.delete}
                                    open={this.state.delete_confirm_open}
                                    setOpen={this.onConfirmDialog}
                                    onConfirm={this.props.onDelete}>
                                    {lang.delete_question}
                                </ConfirmDialog>

                                <IconButton className={classes.refreshIcon} onClick={this.props.onReload} color="inherit" title={lang.refresh}>
                                    <RefreshIcon />
                                </IconButton>

                            </Toolbar>
                        </div>
                        <Box className={classes.dataGrid} >

                            <div style={{ display: 'flex', height: '80vh', minWidth: '0'}}>
                                <div style={{ 
                                    flexGrow: 1,
                                    
                                }}> 
                               
                                    <DataGrid 
                                        className={classes.dataGrid}
                                        localeText={lang.locale.props.MuiDataGrid.localeText}
                                        auth = {this.state.auth}
                                        rows={this.props.rows} 
                                        columns={this.props.columns} 
                                        checkboxSelection
                                        disableSelectionOnClick
                                        pageSize={25}
                                        rowsPerPageOptions={[25]}
                                        pagination
                                        onSelectionModelChange = {this.onSelectionModelChange}

                                    />
                              
                            </div>
                            </div>
                        </Box>

                    </div>
                </div>

            )
        }
    }

    export default withStyles(useStyles)(DatagridPage);

import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import 'moment-timezone';
import 'chartjs-adapter-moment';
import Api from '../../helpers/api';
import { useParams } from 'react-router-dom';
import { Chart } from './Chart';
import lang from '../../language';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/base/Button';


import { Link } from 'react-router-dom';
// import Button from '@mui/base/Button';

import {useTheme } from '@mui/system';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { useMediaQuery, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';

import { makeStyles } from '@material-ui/core/styles';
import { Breakpoint, useBreakpoint } from "./resolve-break.js";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


const { fromUnixTime } = require('date-fns');
const ChartComponent = (props) => {
  const breakpoint = useBreakpoint();
  const { idPatient } = useParams();
  const auth = props.auth;
  const [dataGrafico, setData] = useState([]); /*Parametro con le anlisi*/
  const [chartType, setChartType] = useState('week');
  const [date, setCurrentDate] = useState(Date.now());
  const [stdate, setStartDate] = useState(Date.now());
  const [tempData, setTempData] = useState([]);
  const [isArrowClicked, setIsArrowClicked] = useState(false);
  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleArrowClick = (incremento) => {
    onchangeDate(chartType, incremento);
    setIsArrowClicked(true);
    setTimeout(() => {
      setIsArrowClicked(false);
    }, 200);
  };

  const handleArrowHover = () => {
    setIsArrowHovered(true);
  };

  const handleArrowLeave = () => {
    setIsArrowHovered(false);
  };

  const month = 30 * 24 * 60 * 60 * 1000
  const week = 7 * 24 * 60 * 60 * 1000
  const day = 24 * 60 * 60 * 1000

  const handlerOnChangeValue = (value) => {
    setCurrentDate(value.getTime())


  }
  const handleChange = (event) => {
    setChartType(event.target.value);
    if (event.target.value === 'table') {
      history.push("/analysis/" + idPatient);
    }
  };
  const onchangeDate = (tipo, incremento) => {


    /*trigghera onload data con come data l'incremento, quindi 
    load data va a sottrarre la data attuale, dovrebbe funzionare
    e con questo ho finito l'alg, manca il grafico
    setStartDate serve solo graficamente, la logica si basa su currentDate
    */

    if (tipo == "month" && incremento) {
      setStartDate(date)
      setCurrentDate(date + month)
    } else if (tipo == "month") {
      setStartDate(date)
      setCurrentDate(date - month)

    } else if (tipo == "day" && incremento) {
      setStartDate(date)
      setCurrentDate(date + day)
    } else if (tipo == "day") {
      setStartDate(date)
      setCurrentDate(date - day)

    } else if (tipo == "week" && incremento) {
      setStartDate(date)
      setCurrentDate(date + week)
    } else if (tipo == "week") {
      setStartDate(date)
      setCurrentDate(date - week)

    }
    setChartType(tipo)

  }
  const onLoadData = async (datetoSet) => {

    if (auth) {
      let doctorId = 0;

      if (auth.currentUser.role_id === 2 || auth.currentUser.role_id === 1) {
        doctorId = auth.currentUser.id;

        console.log("patient", auth.currentUser);
      } else {
        doctorId = auth.currentUser.doctor_id;
      }



      if (
        auth.currentUser.role_id === 2 ||
        auth.currentUser.role_id === 1 ||
        auth.currentUser.role_id === 3
      ) {


        let date_s = 0
        let date_f = 0
        switch (chartType) {

          case "week":
            date_f = datetoSet;
            date_s = datetoSet - week;
            setStartDate(date_s)
            break;
          case "day":
            date_f = datetoSet;
            date_s = datetoSet - day
            setStartDate(date_s)
            break;
          default:
            break;
        }
        date_s = new Date(date_s).setHours(0, 0, 0, 0)
        date_f = new Date(date_f).setHours(23, 59, 59, 59)
        const startDate = fromUnixTime(date_s / 1000);
        const finalDate = fromUnixTime(date_f / 1000);

        const dataAnal = await Api.get(Api.urls.users + "/" + doctorId + "/patient/" + idPatient + `/analysis?date='${startDate}'&date='${finalDate}'`, null, {
          'Authorization': 'Bearer ' + auth.token.token
        });


        setTempData(dataAnal);



      } else {
        window.location.href = '/';
        return;
      }
    }
  };


  useEffect(() => {
    onLoadData(date);
  }, [chartType, date]);

  useEffect(() => {
    if (tempData.length >= 0) {
      setData(tempData); // Aggiornamento effettivo di dataGrafico
    }
  }, [tempData]);



  return (
    <>
    <meta name="viewport" content="initial-scale=1, width=device-width" />

    <Grid container spacing={1}>
    {breakpoint < Breakpoint.md ? (
      <Grid xs={12} sx={{display:"flex",justifyContent:"start"}}>
        
        <FormControl style={{width:"50%"}}>
          <InputLabel  style={{width:"100%"}} id="chart-type-label">{lang["select_type"]}</InputLabel>
          <Select
            labelId="chart-type-label"
            id="chart-type-select"
            value={chartType}
            style={{marginBottom:"10px",marginTop:"40px"}}
            onChange={handleChange}
          >
            <MenuItem value={'day'}>{lang["day"]}</MenuItem>
            <MenuItem value={'week'}>{lang["week"]}</MenuItem>
            <MenuItem value={'table'}>{lang["change_view_table"]}</MenuItem>
          </Select>
        </FormControl>
        
      </Grid>
      ) : (
        <>
          <Button className={`custom-button ${chartType === 'day' ? 'selected' : ''}`} onClick={() => setChartType('day')}> <div>{lang["day"]}</div> </Button>
          <Button className={`custom-button ${chartType === 'week' ? 'selected' : ''}`} onClick={() => setChartType('week')}>{lang["week"]} </Button>
          <Link to={"/analysis/" + idPatient} >
            <Button className="custom-button" >
              {lang["change_view_table"]}
            </Button>
          </Link>
        </>
      )}

      {chartType && (
        <>

         
            <Grid xs={12}>
              <p>{lang["select_range"] + lang[chartType]}</p>
            </Grid>  
            <Grid xs={12} >
             
                  <Box sx={{ display:'flex', justifyContent: 'center',flexDirection:'row'  }}>
                  <Box>
                      <ArrowBackIosNewIcon style={{ display: "inline","margin-left":"0px"}} onClick={() => {
                        onchangeDate(chartType, 0)
                        handleArrowClick(0)
                      }}
                        className={`arrow-icon ${isArrowClicked && "clicked"}`}
                        onMouseEnter={handleArrowHover}
                        onMouseLeave={handleArrowLeave}
                      ></ArrowBackIosNewIcon>
                    
                  </Box>

                  <Box  >
                    <DatePicker calendarClassName="custom-calendar" onChange={handlerOnChangeValue} value={new Date(date)} />
                  </Box>
                  <Box>
                        <ArrowForwardIosIcon style={{ display: "inline" }}
                          onClick={
                            () => {
                              onchangeDate(chartType, 1)
                              handleArrowClick(1)
                            }
                          }
                          className={`arrow-icon ${isArrowClicked && "clicked"}`}
                          onMouseEnter={handleArrowHover}
                          onMouseLeave={handleArrowLeave}
                        ></ArrowForwardIosIcon>
                      
                    </Box>
                </Box>
          
            </Grid> 
          
            <Grid xs={12} mt={2} mb={2}>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <strong>{lang["from"]} </strong>&nbsp;
                        <span style={{ whiteSpace: "pre" }}>{new Date(stdate).toLocaleString('it-RM', { weekday: "short", day: "numeric", month: "short" })}</span>
                        <strong>&nbsp;{lang["to"]}</strong>&nbsp;
                        <span style={{ whiteSpace: "pre" }}>{new Date(date).toLocaleString('it-RM', { weekday: "short", day: "numeric", month: "short" })}</span>
                    </div> 
            </Grid>  
                
         
        </>
      )}
      {breakpoint < Breakpoint.md ? (
        <>
          <Grid xs={12} style={{display:"flex", justifyContent:"center"}} >
            <Button className="custom-button" variant="contained" color="primary" onClick={handleOpen}>
              Visualizza grafico
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Box sx={{ width: '100%', height: '100%', overflow: 'auto', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24}}>
              <Grid xs={12}sx={{ width: '80%', margin:"auto"}} >
                <Chart chartType={chartType} data={dataGrafico} />
              </Grid>
              <Grid xs={12} sx={{display:"flex", justifyContent:"center",marginTop:"4%" }}>
                <Button className="custom-button" onClick={handleClose}>Chiudi</Button> {/* Bottone per chiudere il modale */}
              </Grid>
              </Box>
            </Modal>
          </Grid>
        </>
      ) : (
        <Grid xs={12} >
          <Box sx={{ width: '70%', margin:"auto"}} mb={5} >
            <Chart chartType={chartType} data={dataGrafico} ></Chart>
          </Box>
        </Grid>
      )}
    </Grid>
    </>
  );
};


export default ChartComponent;
